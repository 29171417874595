(function ($, site) {
  Drupal.behaviors.headerOffersBannerFormatterV1 = {
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;

      var $formatter = $('.js-header-offers-banner-formatter-v1', context);

      $formatter.each(function () {
        var $this = $(this);
        var $carousel = $this.find('.js-header-offers-banner-formatter-carousel');
        var autoplay = $carousel.data('slides-autoplay');
        var fade = $carousel.data('fade');
        var arrows = $carousel.data('arrows');
        var speed = parseInt($carousel.data('speed'));

        // fade settings
        if (typeof fade === 'undefined') {
          fade = false;
        } else {
          fade = true;
        }

        // plain with defaults
        var settings = {
          infinite: true,
          autoplay: autoplay,
          fade: fade,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: arrows,
          speed: speed,
          draggable: false,
          rtl: site.direction.isRTL
        };

        // Init this carousel with our settings
        $carousel.not('.slick-initialized').slick(settings);
      });
    },
    attached: false
  };
})(jQuery, window.site || {});
